import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@primaveralabs/auth';
import useResponsiveUtility from '../../../../util/responsiveUtility';
import throttle from '../util/throttle';

import ComponentIterator from '../../../../containers/ComponentIterator';
import MainMenuMusicMeets from './MainMenuMusicMeets';
import HeaderMenuMobile from './HeaderMenuMobile';
import BurgerMenuButton from './BurgerMenuButton';
import AuthWrapper from '../../Auth/AuthWrapper';
import { HeaderContext } from '../../../../context/headerContext';

// TODO: change this a more scalable solution
export const CURRENT_EDITION = process.env.PRO_MUSIC_MEETS_ROLE;

const HeaderMusicMeets = ({ registration, components }) => {
  const { authOpen } = useContext(HeaderContext);
  const { deviceScreen } = useResponsiveUtility();
  const { user, token } = useAuth();

  const headerEl = useRef();
  const menuEl = useRef();
  const topHeader = components.filter((component) => component.type !== 'MainMenuMusicMeets');
  const mainMenu = components.find((component) => component.type === 'MainMenuMusicMeets');
  const mobileMenu = components.find((component) => component.type === 'TopHeaderMenuContainerMusicMeets');

  const [menuOpen, setMenuOpen] = useState(false);
  const [headerHeightLogged, setHeaderHeightLogged] = useState();
  const [headerHeightNotLogged, setHeaderHeightNotLogged] = useState();
  const [scroll, setScroll] = useState({ y: 0, lastY: 0 });
  const [showHeader, setShowHeader] = useState(true);
  const [userHasRole, setUserHasRole] = useState(false);

  const handleScroll = () => {
    setScroll((last) => ({ y: window.scrollY, lastY: last.y }));
  };
  const throttleScroll = throttle(handleScroll, 100);

  useEffect(() => {
    if (headerEl?.current) {
      setHeaderHeightLogged(headerEl.current.clientHeight);
    }
    if (menuEl?.current) {
      setHeaderHeightNotLogged(menuEl.current.clientHeight);
    }
    if (window && typeof window !== 'undefined') {
      window.addEventListener('scroll', throttleScroll);
    }
    return () => window.removeEventListener('scroll', throttleScroll);
  }, []);

  useEffect(() => {
    if ((userHasRole && (scroll.y < headerHeightLogged || scroll.y === 0))
    || (!userHasRole && (scroll.y < headerHeightNotLogged || scroll.y === 0))) {
      setShowHeader(true);
    } else if ((userHasRole && scroll.y > headerHeightLogged && (scroll.y - scroll.lastY > 0))
    || (!userHasRole && scroll.y > headerHeightNotLogged && (scroll.y - scroll.lastY > 0))) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [scroll, userHasRole, headerHeightLogged, headerHeightNotLogged]);

  useEffect(() => {
    setMenuOpen(false);
  }, [deviceScreen, token, user]);

  useEffect(() => {
    if (window && typeof window !== 'undefined') {
      if (menuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [menuOpen]);

  useEffect(() => {
    if (token && user?.roles.includes(CURRENT_EDITION)) {
      setUserHasRole(true);
    } else {
      setUserHasRole(false);
    }
  }, [token, user]);

  return (
    <>
      <div ref={headerEl} className={`full-width w-full z-header sticky pin-t ${userHasRole ? 'userLogged' : ''} ${showHeader ? 'header-MusicMeets-animation-visible' : 'header-MusicMeets-animation'} `}>
        <div ref={menuEl} className="bg-black w-full flex font-americaMonoMedium relative">
          <ComponentIterator components={topHeader} />
          <div className="hidden sm:flex sm:absolute sm:pin-b sm:pin-r sm:pr-4 sm:h-full sm:pt-pro-top-menu sm:items-center">
            <BurgerMenuButton onClickCallback={() => setMenuOpen(!menuOpen)} />
          </div>
        </div>
        {deviceScreen === 'sm' && menuOpen && <HeaderMenuMobile items={mobileMenu.components} />}
        {userHasRole && mainMenu && !menuOpen && <MainMenuMusicMeets params={mainMenu.params} />}
      </div>
      {authOpen && <AuthWrapper registration={registration} />}
    </>
  );
};

HeaderMusicMeets.propTypes = {
  registration: PropTypes.shape().isRequired,
  components: PropTypes.arrayOf(PropTypes.shape()),
};

HeaderMusicMeets.defaultProps = {
  components: [],
};

export default HeaderMusicMeets;
