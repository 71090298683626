import React, { useState, useEffect, useRef, useContext } from 'react';
import { PropTypes } from 'prop-types';

import { withRouter } from 'react-router-dom';
import { useAuth } from '@primaveralabs/auth';
import Link from '../../../util/link';

import RoutesContext, { useRoutes } from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import imageModifier from '../helpers/imageModifier';
import translateObj from '../../../util/translateObj';
import TitleArtistLineH1 from '../Text/TitleArtistLineH1';
import Navigator from '../Navigator';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import { HeaderContext } from '../../../context/headerContext';
import getUserData from '../../../api/graphql/queries/getUserData';
import LoadIcon from '../LoadIcon';
import setUserFavourites from '../../../api/graphql/mutations/setUserFavourite';

const ARIA_LABEL_NEXT = {
  en: 'Next artist',
  es: 'Siguiente artista',
  ca: 'Següent artista',
  pt: 'Próximo artista',
};
const ARIA_LABEL_PREV = {
  en: 'Previous artist',
  es: 'Artista anterior',
  ca: 'Artista anterior',
  pt: 'Artista anterior',
};
const ARIA_LABEL_ADD_FAVORITE = {
  en: 'Add to favourites',
  es: 'Añadir a favoritos',
  ca: 'Afegir a favorits',
  pt: 'Adicionar aos favoritos',
};
const ARIA_LABEL_DELETE_FAVORITE = {
  en: 'Delete from favourites',
  es: 'Eliminar de favoritos',
  ca: 'Eliminar de favorits',
  pt: 'Excluir dos favoritos',
};
const ARIA_LABEL_LOGIN = {
  en: 'Open login screen',
  es: 'Abrir pantalla de inicio de sesión',
  ca: 'Obre la pantalla d\'inici de sessió',
  pt: 'Abrir tela de login',
};

const FAV_ICON_STROKE = (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
    <path
      fill="currentColor"
      d="m12 21.35-1.45-1.3c-1.683-1.517-3.075-2.825-4.175-3.925-1.1-1.1-1.975-2.088-2.625-2.963-.65-.875-1.104-1.679-1.362-2.412A6.751 6.751 0 0 1 2 8.5c0-1.567.525-2.875 1.575-3.925C4.625 3.525 5.933 3 7.5 3c.867 0 1.692.183 2.475.55A5.93 5.93 0 0 1 12 5.1a5.93 5.93 0 0 1 2.025-1.55A5.769 5.769 0 0 1 16.5 3c1.567 0 2.875.525 3.925 1.575C21.475 5.625 22 6.933 22 8.5c0 .767-.13 1.517-.388 2.25-.259.733-.713 1.537-1.362 2.412-.65.875-1.525 1.863-2.625 2.963s-2.492 2.408-4.175 3.925L12 21.35Zm0-2.7c1.6-1.433 2.917-2.663 3.95-3.688 1.033-1.025 1.85-1.917 2.45-2.674.6-.759 1.017-1.434 1.25-2.026.233-.592.35-1.18.35-1.762 0-1-.333-1.833-1-2.5s-1.5-1-2.5-1c-.783 0-1.508.22-2.175.662-.667.441-1.125 1.004-1.375 1.688h-1.9c-.25-.683-.708-1.246-1.375-1.688A3.852 3.852 0 0 0 7.5 5c-1 0-1.833.333-2.5 1S4 7.5 4 8.5c0 .583.117 1.171.35 1.763.233.592.65 1.267 1.25 2.025s1.417 1.65 2.45 2.675A108.542 108.542 0 0 0 12 18.65Z"
    />
  </svg>
);
const FAV_ICON_FILL = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="m12 21.35-1.45-1.3c-1.683-1.517-3.075-2.825-4.175-3.925-1.1-1.1-1.975-2.088-2.625-2.963-.65-.875-1.104-1.679-1.362-2.412A6.751 6.751 0 0 1 2 8.5c0-1.567.525-2.875 1.575-3.925C4.625 3.525 5.933 3 7.5 3c.867 0 1.692.183 2.475.55A5.93 5.93 0 0 1 12 5.1a5.93 5.93 0 0 1 2.025-1.55A5.769 5.769 0 0 1 16.5 3c1.567 0 2.875.525 3.925 1.575C21.475 5.625 22 6.933 22 8.5c0 .767-.13 1.517-.388 2.25-.259.733-.713 1.537-1.362 2.412-.65.875-1.525 1.863-2.625 2.963s-2.492 2.408-4.175 3.925L12 21.35Z"
    />
  </svg>
);

const SliderBannerArtistPD = ({
  image, title, prevArtist, nextArtist, match, eventSlugName, tags, url, artist, eventPost,
}) => {
  console.log("🚀 ~ eventSlugName:", eventSlugName)
  const { language } = useRoutes();
  const { user, token } = useAuth();
  const { setAuthOpen } = useContext(HeaderContext);
  const [deviceWidth, setDeviceWidth] = useState('lg');
  const responsiveUtility = useRef();
  const [favourites, setFavourites] = useState({ favouriteArtists: [], favouriteArtistSets: [] });
  const [isFavourite, setIsFavourite] = useState(false);
  const [starDisabled, setStarDisabled] = useState(false);

  const getWidth = () => {
    // actions when window is resized
    setDeviceWidth(responsiveUtility.current.deviceScreen(true));
  };
  const styleNavButtons = (img, lang) => ({
    backgroundImage: `url(${imageModifier(translateObj(img, lang), '200x200')})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  });

  const handleSetFavouriteArtist = () => {
    setStarDisabled(true);
    const newFavourites = [...favourites.favouriteArtists];
    const index = newFavourites.indexOf(artist);

    if (index >= 0) {
      newFavourites.splice(index, 1);
      setIsFavourite(false);
    } else {
      newFavourites.push(artist);
      setIsFavourite(true);
    }

    setUserFavourites({ artist }).then(() => {
      setFavourites({ favouriteArtists: newFavourites, favouriteArtistSets: favourites.favouriteArtistSets });
    });
    setStarDisabled(false);
  };

  // get user favourites
  useEffect(() => {
    if (user && token) {
      getUserData()
        .then((res) => {
          if (res.favourites) {
            setFavourites(res.favourites);
            // mark artist as favourite if necessary
            if (res.favourites.favouriteArtists?.includes(artist)) {
              setIsFavourite(true);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user, token]);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        let imageurl = `url(${imageModifier(translateObj(image, localization.language), '1400x620')})`;
        let backgroundPosition = 'center';
        if (deviceWidth === 'xxl') { imageurl = `url(${imageModifier(translateObj(image, localization.language), '1900x820')})`; backgroundPosition = 'center top'; }
        if (deviceWidth === 'lg') imageurl = `url(${imageModifier(translateObj(image, localization.language), '1400x620')})`;
        if (deviceWidth === 'md') imageurl = `url(${imageModifier(translateObj(image, localization.language), '1400x620')})`;
        if (deviceWidth === 'sm') imageurl = `url(${imageModifier(translateObj(image, localization.language), '414x420')})`;
        const backgroundStyle = {
          backgroundImage: imageurl,
          backgroundPosition,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        return (
          <>
            <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} />
            { deviceWidth === 'sm' ? null : <Navigator title={title} />}
            <div className="w-full full-width">
              <div key={translateObj(image, localization.language)} className="h-carouselXL md:h-carouselMD sm:h-carouselSM lg:h-620 w-full sliderComponent relative" style={backgroundStyle}>
                <div className="text-white flex items-end h-full relative">
                  {prevArtist && (
                  <Link to={`/${eventPost}/${prevArtist.slugName}${eventSlugName ? `?e=${eventSlugName}` : ''}`} localizationpath={localization.path} aria-label={translateObj(ARIA_LABEL_PREV, localization.language)}>
                    <div
                      className="flex cursor-pointer ml-7 sm:ml-2 md:ml-5 z-10"
                      style={{
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <img src="https://assets.primaverasound.com/ps/images/arrow_left_white.svg" alt="Prev Artists" />
                      <div className="backgroundImgGray w-80px md:w-60px sm:w-50px h-80px md:h-60px sm:h-50px ml-5 md:ml-2 sm:ml-2 rounded-full" style={styleNavButtons(prevArtist.postDescription.image, localization.language)} />
                    </div>
                  </Link>
                  )}
                  <div className="flex justify-around sm:flex-col sm:justify-end w-full px-36 md:px-20 sm:px-5 h-full items-end pb-20 sm:pb-5">
                    <div className="w-2/3 sm:w-full h-full sm:h-auto flex flex-col justify-end mr-12 sm:mr-0 sm:mb-4 ">
                      <div className="mt-4 relative z-10 flex items-center">
                        <TitleArtistLineH1 artist={translateObj(title, localization.language)} />
                        <div>
                          {starDisabled ? <LoadIcon height={30} width={30} classes="w-auto block flex items-center pl-4 sm:pl-2 md:pl-3" color="white" /> : (
                            <div>
                              {(token && user)
                              // user logged: can add/remove favourites
                                ? (
                                  <div
                                    tabIndex="0"
                                    role="button"
                                    className="cursor-pointer text-brandGreen500 pl-4 sm:pl-2 md:pl-3"
                                    onClick={() => handleSetFavouriteArtist()}
                                    onKeyDown={(event) => {
                                      if (event.key === 'Enter') {
                                        handleSetFavouriteArtist();
                                      }
                                    }}
                                    aria-label={isFavourite ? translateObj(ARIA_LABEL_DELETE_FAVORITE, language) : translateObj(ARIA_LABEL_ADD_FAVORITE, language)}
                                  >
                                    <div className="text-barcelona w-10 sm:w-5 md:w-26px flex items-center justify-center">
                                      {isFavourite ? FAV_ICON_FILL : FAV_ICON_STROKE}
                                    </div>
                                  </div>
                                ) : (
                                // user not logged: open auth modal
                                  <div role="button" className="flex justify-center">
                                    <button
                                      type="button"
                                      onClick={() => setAuthOpen('login')}
                                      className="text-brandGreen500 font-americaMonoRegular cursor-pointer text-center pl-4 sm:pl-2 md:pl-3 uppercase flex justify-center items-center"
                                      aria-label={translateObj(ARIA_LABEL_LOGIN, language)}
                                    >
                                      <div className="text-barcelona w-10 sm:w-5 md:w-26px flex items-center justify-center">{FAV_ICON_STROKE}</div>
                                    </button>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-1/3 sm:w-full flex flex-col justify-start">
                      {/* <div className="mt-4 relative z-10">
                      {tags && tags.length && tags.map((tag) => {
                        const tagsWithSpaces = tag.split(' ');
                        return tagsWithSpaces.length < 2
                          ? <SimpleTag text={{ en: tag }} key={tag} category={tag} borderColor={tag} />
                          : <SimpleTag text={{ en: tag }} key={tag} category={tagsWithSpaces[0]} borderColor={tagsWithSpaces[0]} />;
                      })
                      }
                    </div> */}
                    </div>
                  </div>
                  {nextArtist && (
                  <Link to={`/${eventPost}/${nextArtist.slugName}${eventSlugName ? `?e=${eventSlugName}` : ''}`} localizationpath={localization.path} aria-label={translateObj(ARIA_LABEL_NEXT, localization.language)}>
                    <div
                      className="flex cursor-pointer mr-7 sm:mr-2 md:mr-5 z-10"
                      style={{
                        position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)',
                      }}
                    >
                      <div className="backgroundImgGray w-80px md:w-60px sm:w-50px h-80px md:h-60px sm:h-50px mr-5 md:ml-2 sm:mr-2 rounded-full" style={styleNavButtons(nextArtist.postDescription.image, localization.language)} />
                      <img src="https://assets.primaverasound.com/ps/images/arrow_right_white.svg" alt="next Artists" />
                    </div>
                  </Link>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </RoutesContext.Consumer>
  );
};

SliderBannerArtistPD.propTypes = {
  image: PropTypes.shape({}),
  title: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.string),
  match: PropTypes.shape().isRequired,
  prevArtist: PropTypes.shape(),
  nextArtist: PropTypes.shape(),
  eventSlugName: PropTypes.string,
  url: PropTypes.string,
  artist: PropTypes.string,
  eventPost: PropTypes.string,
};

SliderBannerArtistPD.defaultProps = {
  prevArtist: null,
  nextArtist: null,
  title: null,
  tags: null,
  image: null,
  eventSlugName: '',
  url: '',
  artist: '',
  eventPost: null,
};

export default withRouter(SliderBannerArtistPD);
