import React, {
  Component, useContext, useEffect, useState, useRef,
} from 'react';
import PropTypes, { array } from 'prop-types';
import moment from 'moment';
import stickybits from 'stickybits';
import { TweenMax } from 'gsap';

import { useAuth } from '@primaveralabs/auth';
import { withRouter } from 'react-router-dom';
import LineupFilter from './Collapsible/LineupFilter';
import LineupList from './LineupList';
import LineupTable from './LineupTable';
import LineupGrid from './LineupGrid';

import getLineup from '../../api/graphql/queries/getLineup';
import LineupContext from '../../context/lineupContext';
import MediaDescription from './MediaDescription';

import { UserProfileContext } from '../../context/userProfileContext';
import getUserFavourites from '../../api/graphql/queries/getUserFavourites';
import setUserFavourites from '../../api/graphql/mutations/setUserFavourite';

const TIMEZONE_DEFAULT = 'Europe/Madrid';

class LineupContainer extends Component {
  static contextType = LineupContext;

  constructor(props) {
    super(props);

    this.state = {
      view: '',
      dates: [],
      tags: [],
      activeDays: [],
      venues: {},
      activeVenues: [],
      filteredArtistsByDay: [],
      viewMode: 'all', // all, favourites
      searchValue: null,
      activeTags: [],
      timezone: '',
      lineupDataFromQuery: {}, // in order to handle favorites
      userFavorites: [], // user favorites coming from render component (see below)
    };

    this.view = null;
    this.venues = null;
    this.venuesLookupTable = null;
    this.artists = null;
    this.dates = null;
    this.artistsByDayAndVenues = null;
    this.filteredArtistsByDay = null;
    this.activeDays = [];
    this.activeTags = [];
    this.activeVenues = [];
    this.viewMode = 'all';
    this.searchValue = null;

    this.lineupFilter = React.createRef();
    this.y = 0;
  }

  componentWillMount() {
    const { lineupData, clearLineup } = this.context;

    if (lineupData) {
      this.processLineupData(lineupData);
      clearLineup();
    }
  }

  componentDidMount() {
    const { eventSlug, initialView } = this.props;

    if (typeof window === 'undefined') return '';

    const urlParams = new URLSearchParams(window.location.search);
    const venue = urlParams?.get('venue');
    const day = urlParams?.get('day');

    if (venue) this.filterVenueSelected([venue]);
    if (day) this.filterDaySelected(day);

    this.setState(() => ({ view: initialView }));
    this.initSticky();
    this.fetchLineupData(eventSlug);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventSlug !== this.props.eventSlug) {
      const { eventSlug, initialView } = this.props;
      this.setState(() => ({ view: initialView }));
      this.initSticky();
      this.fetchLineupData(eventSlug);
      window.addEventListener('scroll', this.handleScroll);
      // this.fetchLineupData('primavera-sound-2019-barcelona');
    }
  }

  componentWillUnmount() {
    this.stopSticky();
    window.removeEventListener('scroll', this.handleScroll);
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------

  storeUserFavs = (favs) => {
    this.setState({ userFavorites: favs });
  }

  updateLineupWithFavs = (name, favs = []) => {
    const { userFavorites } = this.state;
    let favsToBeRemoved = [];
    const areArraysEqual = userFavorites?.length === favs.length && userFavorites.sort().every((value) => value === favs.sort());

    if (!areArraysEqual) {
      favsToBeRemoved = userFavorites.map((f) => { if (!favs.includes(f)) return f; }).filter((i) => i !== undefined);
      this.setState({ userFavorites: favs });
    }
    const { lineupDataFromQuery } = this.state;
    if (Object.keys(lineupDataFromQuery).length) {
      const lineupDataFromQAdj = { ...lineupDataFromQuery };
      const favsArray = favs;
      favsArray.forEach((fav) => {
        const artistIndex = lineupDataFromQAdj.artists.findIndex((a) => a.artistSlugName === fav);
        if (artistIndex >= 0) {
          const venuesLength = lineupDataFromQAdj.artists[artistIndex]?.venues?.length || 0;
          for (let i = 0; i < venuesLength; i++) {
            lineupDataFromQAdj.artists[artistIndex].venues[i].artistSetIsFavorite = true;
          }
        }
      });
      if (favsToBeRemoved.length) {
        favsToBeRemoved.forEach((fav) => {
          const artistIndex = lineupDataFromQAdj.artists.findIndex((a) => a.artistSlugName.includes(fav));
          if (artistIndex >= 0) {
            const venuesLength = lineupDataFromQAdj.artists[artistIndex]?.venues?.length || 0;
            for (let i = 0; i < venuesLength; i++) {
              lineupDataFromQAdj.artists[artistIndex].venues[i].artistSetIsFavorite = false;
            }
          }
        });
      }
      this.processLineupData(lineupDataFromQAdj);
    }
  }

  fetchLineupData = (name, favs = []) => {
    getLineup(name).then((lineupData) => {
      if (lineupData) {
        this.setState({ lineupDataFromQuery: lineupData, timezone: lineupData.timezone || TIMEZONE_DEFAULT, lineupLoaded: true, eventPost: lineupData.eventPost ?? 'artist' });
        this.processLineupData(lineupData);
      } else {
        console.log('Lineup = null');
      }
    });
  };

  handleScroll = () => {
    const header = document.querySelector('#header');
    if (!header) return;
    const { top, height } = header.getBoundingClientRect();
    const t = top + height;
    TweenMax.set('.lineUpFilterWrapper', {
      top: t > 0 ? t : 0,
    });
  };

  initSticky = () => {
    this.lineupFilterStickyInstance = stickybits('.lineUpFilterWrapper');
  };

  stopSticky = () => {
    if (this.lineupFilterStickyInstance) {
      this.lineupFilterStickyInstance.cleanup();
    }
  };

  processLineupData = (lineupData) => {
    const {
      artists, venues, showDate, showDateTime, showVenue,
    } = lineupData;
    this.artists = artists;
    this.venuesLookupTable = JSON.parse(venues);
    this.venues = this.getVenues();
    this.dates = this.getDates();
    this.tags = this.getTags();
    this.artistsByDayAndVenues = this.getArtistsByDayAndVenues();
    this.filteredArtistsByDay = this.filterArtists();

    this.setState({
      venues: this.venues,
      dates: this.dates,
      tags: this.tags,
      filteredArtistsByDay: this.filteredArtistsByDay,
      artistsByDayAndVenues: this.artistsByDayAndVenues,
      showDate,
      showDateTime,
      showVenue,
    }, () => {
      const { location: { search } } = this.props;

      if (search) {
        const [, text] = search.split('=');
        if (text === 'favourites') {
          this.updateViewMode('favourites');
        }
      }
    });
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------

  filterArtists = () => {
    const {
      hasConferences,
      doNotshowMultipleVenues = false,
    } = this.props;

    const realActiveDays = this.activeDays.length === 0 ? this.dates : this.activeDays;
    const artistsByDayFiltered = {};
    const realActiveVenues = this.activeVenues.length > 0 ? this.activeVenues : Object.keys(this.venues);

    realActiveDays.forEach((day) => {
      if (!artistsByDayFiltered[day]) artistsByDayFiltered[day] = {};
      realActiveVenues.forEach((venue) => {
        if (this.artistsByDayAndVenues[day][venue]) {
          if (this.viewMode === 'all') {
            artistsByDayFiltered[day][venue] = this.artistsByDayAndVenues[day][venue].main;
          } else if (this.viewMode === 'favourites') {
            artistsByDayFiltered[day][venue] = this.artistsByDayAndVenues[day][venue].favourites;
          }
        }
      });

      if (doNotshowMultipleVenues) {
        artistsByDayFiltered[day].All = Object.values(artistsByDayFiltered[day]).flat();
      }
    });

    const artistsBySearchFiltered = {};

    if (this.searchValue !== null && this.searchValue !== '') {
      Object.entries(artistsByDayFiltered).forEach(([day, venuesList]) => {
        Object.entries(venuesList).forEach(([venueName, artistSets]) => {
          const artistMatches = [];
          artistSets.forEach((artistSet) => {
            const {
              artistName, artistSetName, artistSetReadableName, shortTitle,
            } = artistSet;
            if (artistName.toLowerCase().includes(this.searchValue.toLowerCase()) || artistSetName.toLowerCase().includes(this.searchValue.toLowerCase()) || artistSetReadableName.en.toLowerCase().includes(this.searchValue.toLowerCase()) || (hasConferences && shortTitle && shortTitle.en.toLowerCase().includes(this.searchValue.toLowerCase()))) {
              if (!artistsBySearchFiltered[day]) artistsBySearchFiltered[day] = {};
              artistMatches.push(artistSet);
              artistsBySearchFiltered[day][venueName] = artistMatches;
            }
          });
        });
      });
      return artistsBySearchFiltered;
    }
    return artistsByDayFiltered;
  };

  filterArtistsByTag = () => {
    const realActiveDays = this.activeDays.length === 0 ? this.dates : this.activeDays;

    const artistsByDayFiltered = {};

    const realActiveVenues = this.activeVenues.length > 0 ? this.activeVenues : Object.keys(this.venues);

    realActiveDays.forEach((day) => {
      if (!artistsByDayFiltered[day]) artistsByDayFiltered[day] = {};
      realActiveVenues.forEach((venue) => {
        if (this.artistsByDayAndVenues[day][venue]) {
          if (this.viewMode === 'all') {
            artistsByDayFiltered[day][venue] = this.artistsByDayAndVenues[day][venue].main;
          } else if (this.viewMode === 'favourites') {
            artistsByDayFiltered[day][venue] = this.artistsByDayAndVenues[day][venue].favourites;
          }
        }
      });
    });

    const realActiveTags = this.activeTags.length === 0 ? [] : this.activeTags;
    const artistsByTagFiltered = {};

    Object.entries(artistsByDayFiltered).forEach(([day, venuesList]) => {
      Object.entries(venuesList).forEach(([venueName, artistSets]) => {
        const artistMatches = [];
        artistSets.forEach((artistSet) => {
          const {
            artistSetGenres,
          } = artistSet;
          if (realActiveTags.length === 0) {
            if (!artistsByTagFiltered[day]) artistsByTagFiltered[day] = {};
            artistMatches.push(artistSet);
            artistsByTagFiltered[day][venueName] = [...new Set(artistMatches)];
          } else {
            realActiveTags.forEach((tag) => {
              if (artistSetGenres && artistSetGenres.length && artistSetGenres.includes(tag)) {
                if (!artistsByTagFiltered[day]) artistsByTagFiltered[day] = {};
                artistMatches.push(artistSet);
                artistsByTagFiltered[day][venueName] = [...new Set(artistMatches)];
              }
            });
          }
        });
      });
    });

    return artistsByTagFiltered;
  };

  getDates = () => {
    const { timezone } = this.state;
    // NOTE: can get this data from the BE??
    moment.locale('es');
    const dates = [];
    this.artists.forEach((artist) => {
      const { venues } = artist;
      venues.forEach((venue) => {
        const { dateTimeStartHuman } = venue;
        const day = moment(dateTimeStartHuman / 1000, 'X').tz(timezone).format('L');
        if (!dates.includes(day)) {
          dates.push(day);
        }
      });
    });

    const dateToNumber = (date) => {
      // NOTE: convert date "01/06/2018" to 20180601 and sort by asc
      const dateSplitted = date.split('/');
      return Number(dateSplitted[2] + dateSplitted[1] + dateSplitted[0]);
    };

    const datesSorted = dates.sort((a, b) => dateToNumber(a) - dateToNumber(b));

    return datesSorted;
  };

  getTags = () => {
    const tags = [];
    this.artists.forEach((artist) => {
      const { venues } = artist;
      venues.forEach((venue) => {
        venue.hasOwnProperty('artistSetGenres') && venue.artistSetGenres !== null && venue.artistSetGenres.length && venue.artistSetGenres.forEach((tag) => tags.push(tag));
      });
    });
    const tagsSorted = [...new Set(tags)];

    return tagsSorted;
  };

  getVenues = () => {
    let venues = {};

    const venueSlugNames = Object.keys(this.venuesLookupTable).sort();

    venueSlugNames.forEach((venueSlugName) => {
      if (venueSlugName !== null) {
        venues = { ...venues, [venueSlugName]: this.venuesLookupTable[venueSlugName].venueReadableName };
      } else {
        venues = { ...venues, tbd: 'TBD' };
      }
    });

    return venues;
  };

  getArtistsByDayAndVenues = () => {
    const { timezone } = this.state;
    const artistsByDayAndVenues = {};
    this.artists.forEach((artist) => {
      const { venues } = artist;
      venues.forEach((venue, index) => {
        const { dateTimeStartHuman, venueSlugName } = venue;
        const day = moment(dateTimeStartHuman / 1000, 'X').tz(timezone).format('L');
        const item = {
          ...venue,
          ...artist,
          duration: venue.duration,
          ...this.venuesLookupTable[venueSlugName],
        };
        const isItemFavorite = venues[index].artistSetIsFavorite;
        item.artistSetIsFavorite = isItemFavorite;
        const itemAdj = JSON.parse(JSON.stringify(item));

        delete itemAdj.venues;

        if (!artistsByDayAndVenues[day]) {
          artistsByDayAndVenues[day] = {
            all: { main: [], favourites: [] },
          };
        }

        if (!artistsByDayAndVenues[day][venueSlugName]) {
          artistsByDayAndVenues[day][venueSlugName] = { main: [], favourites: [] };
        }

        if (isItemFavorite) {
          artistsByDayAndVenues[day].all.favourites.push(itemAdj);
          artistsByDayAndVenues[day][venueSlugName].favourites.push(itemAdj);
          artistsByDayAndVenues[day].all.main.push(itemAdj);
          artistsByDayAndVenues[day][venueSlugName].main.push(itemAdj);
        } else {
          artistsByDayAndVenues[day].all.main.push(itemAdj);
          artistsByDayAndVenues[day][venueSlugName].main.push(itemAdj);
        }
      });
    });

    return artistsByDayAndVenues;
  };

  // getArtistsByDayAndVenuesReduced = () => {
  //   const artistsByDayAndVenues = {};
  //   const artistFiltered = [...this.artists].filter(a => (a.artistSlugName === '100-gecs' || a.artistSlugName === 'abbath' || a.artistSlugName === 'penelope-isles' || a.artistSlugName === 'paloma-mami'));
  //   artistFiltered.forEach((artist) => {
  //     const { venues } = artist;
  //     venues.forEach((venue, index) => {
  //       const { dateTimeStartHuman, venueSlugName } = venue;
  //       const day = moment(dateTimeStartHuman / 1000, 'X').tz(timezone).format('L');
  //       const item = {
  //         ...artist,
  //         ...venue,
  //         ...this.venuesLookupTable[venueSlugName],
  //       };
  //       const isItemFavorite = venues[index].artistSetIsFavorite;
  //       item.artistSetIsFavorite = isItemFavorite;
  //       const itemAdj = JSON.parse(JSON.stringify(item));

  //       delete itemAdj.venues;

  //       if (!artistsByDayAndVenues[day]) {
  //         artistsByDayAndVenues[day] = {
  //           all: { main: [], favourites: [] },
  //         };
  //       }

  //       if (!artistsByDayAndVenues[day][venueSlugName]) {
  //         artistsByDayAndVenues[day][venueSlugName] = { main: [], favourites: [] };
  //       }

  //       if (isItemFavorite) {
  //         artistsByDayAndVenues[day].all.favourites.push(itemAdj);
  //         artistsByDayAndVenues[day][venueSlugName].favourites.push(itemAdj);
  //         artistsByDayAndVenues[day].all.main.push(itemAdj);
  //         artistsByDayAndVenues[day][venueSlugName].main.push(itemAdj);
  //       } else {
  //         artistsByDayAndVenues[day].all.main.push(itemAdj);
  //         artistsByDayAndVenues[day][venueSlugName].main.push(itemAdj);
  //       }
  //     });
  //   });

  //   console.log('LineupContainer -> getArtistsByDayAndVenues -> artistsByDayAndVenues!!!problem', artistsByDayAndVenues);
  //   return artistsByDayAndVenues;
  // };

  // ----------------------------------------------------------------------------------------------------------------------------------------

  filterDaySelected = async (day) => {
    this.activeDays.includes(day) ? this.activeDays.splice(this.activeDays.indexOf(day), 1) : this.activeDays.push(day);
    this.filteredArtistsByDay = this.filterArtists();

    this.setState({
      activeDays: this.activeDays,
      filteredArtistsByDay: this.filteredArtistsByDay,
    });
  };

  filterTagSelected = async (tag) => {
    this.activeTags.includes(tag) ? this.activeTags.splice(this.activeTags.indexOf(tag), 1) : this.activeTags.push(tag);
    this.filteredArtistsByDay = this.filterArtistsByTag();

    this.setState({
      activeTags: this.activeTags,
      filteredArtistsByDay: this.filteredArtistsByDay,
    });
  };

  filterVenueSelected = async (newVenues) => {
    this.activeVenues = newVenues;
    this.filteredArtistsByDay = this.filterArtists();

    this.setState({
      activeVenues: this.activeVenues,
      filteredArtistsByDay: this.filteredArtistsByDay,
    });
  };

  updateSearchFilter = (searchValue) => {
    this.searchValue = searchValue;
    this.filteredArtistsByDay = this.filterArtists();

    this.setState({
      searchValue: this.searchValue,
      filteredArtistsByDay: this.filteredArtistsByDay,
    });
  };

  updateViewMode = (viewMode) => {
    this.viewMode = viewMode;
    this.filteredArtistsByDay = this.filterArtists();
    if (viewMode === 'poster') {
      return this.setState({
        viewMode: this.viewMode,
      });
    }
    this.setState({
      viewMode: this.viewMode,
      filteredArtistsByDay: this.filteredArtistsByDay,
    });
  };

  updateActiveDays = (activeDays) => {
    this.activeDays = activeDays;
    this.filteredArtistsByDay = this.filterArtists();

    this.setState({
      activeDays: this.activeDays,
      filteredArtistsByDay: this.filteredArtistsByDay,
    });
  };

  updateView = (view) => {
    this.setState({ view });
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------

  render() {
    const {
      venues, dates, tags, view, filteredArtistsByDay, activeDays, activeTags, activeVenues, viewMode, searchValue, showDate, showDateTime, showVenue, artistsByDayAndVenues, timezone,
    } = this.state;

    const {
      lineUpFilter, showList, showTable, showGrid, showPoster, showFilters, eventSlug, hasConferences, multipleLineUpsOnPage, poster, match: { params },
    } = this.props;

    return (
      <LineupRenderContainer
        {...this.props}
        {...this.state}
        filterVenueSelected={this.filterVenueSelected}
        filterDaySelected={this.filterDaySelected}
        filterTagSelected={this.filterTagSelected}
        updateViewMode={this.updateViewMode}
        updateView={this.updateView}
        updateActiveDays={this.updateActiveDays}
        updateSearchFilter={this.updateSearchFilter}
        updateLineupWithFavs={this.updateLineupWithFavs}
        lineupFilterRef={this.lineupFilter}
        storeFavoritesInLineUpContainerParent={this.storeUserFavs}
        category={params.hasOwnProperty('section') ? params.section : 'black'}
        timezone={timezone}
      />

    // <div className="w-full flex flex-col items-center pt-4" id="line-up-container">
    //   {showFilters && venues && (
    //     <div className={`lineUpFilterWrapper z-20 w-full flex justify-center ${view === 'grid' ? 'bg-white-dark' : ''} `}>
    //       <LineupFilter
    //         wrappedComponentRef={(c) => {
    //           this.lineupFilter = c;
    //         }}
    //         {...lineUpFilter}
    //         dates={dates}
    //         tags={tags}
    //         venues={venues}
    //         filterVenueSelected={this.filterVenueSelected}
    //         filterDaySelected={this.filterDaySelected}
    //         filterTagSelected={this.filterTagSelected}
    //         updateViewMode={this.updateViewMode}
    //         updateView={this.updateView}
    //         updateActiveDays={this.updateActiveDays}
    //         activeDays={activeDays}
    //         activeTags={activeTags}
    //         activeVenues={activeVenues}
    //         activeViewMode={viewMode}
    //         activeView={view}
    //         searchValue={searchValue}
    //         updateSearchFilter={this.updateSearchFilter}
    //         showTable={showTable}
    //         showList={showList}
    //         showGrid={showGrid && showDateTime && showDate}
    //         showDate={showDate}
    //         showPoster={showPoster}
    //         showDateTime={showDateTime}
    //         multipleLineUpsOnPage={multipleLineUpsOnPage}
    //       />
    //     </div>
    //   )}

    //   {filteredArtistsByDay && view === 'list' && <LineupList eventSlug={eventSlug} artists={filteredArtistsByDay} />}

    //   {filteredArtistsByDay && view === 'table' && <LineupTable timezone={timezone} eventSlug={eventSlug} artistsByDay={filteredArtistsByDay} venues={venues} showDate={showDate} showDateTime={showDateTime} showVenue={showVenue} hasConferences={hasConferences} />}

    //   {showDateTime && showDate && filteredArtistsByDay && view === 'grid' && <LineupGrid venues={venues} eventSlug={eventSlug} artistsByDay={filteredArtistsByDay} hasConferences={hasConferences} />}
    //   {showPoster && view === 'poster' && <MediaDescription imageUrl={{ en: poster }} /> }
    // </div>
    );
  }
}

const LineupRenderContainer = ({
  lineupLoaded,
  venues,
  dates,
  tags,
  view,
  filteredArtistsByDay,
  activeDays,
  activeTags,
  activeVenues,
  viewMode,
  searchValue,
  showDate,
  showDateTime,
  showVenue,
  lineUpFilter,
  showList,
  doNotshowMultipleVenues = false,
  showTable,
  showGrid,
  showPoster,
  showFilters,
  eventSlug,
  hasConferences,
  multipleLineUpsOnPage,
  poster,
  filterVenueSelected,
  filterDaySelected,
  filterTagSelected,
  updateViewMode,
  updateView,
  updateActiveDays,
  updateSearchFilter,
  artistsByDayAndVenues,
  updateLineupWithFavs,
  lineupFilterRef,
  storeFavoritesInLineUpContainerParent,
  category,
  userFavorites,
  timezone,
  eventPost,
}) => {
  const userContext = useContext(UserProfileContext);
  const { token, user } = useAuth();

  const [favorites, setFavorites] = useState([]);
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false);

  useEffect(() => {
    if (lineupLoaded) {
      getUserFavourites().then((res) => {
        setFavorites(res);
        storeFavoritesInLineUpContainerParent(res);
        updateLineupWithFavs(eventSlug, res);
      });
    }
  }, [lineupLoaded]);

  useEffect(() => {
    if (favorites.length && Object.keys(dates).length && Object.keys(venues).length && !isFirstLoadDone) {
      setIsFirstLoadDone(true);
    }
  }, [favorites, dates, venues]);

  const handleSetArtistSetFavorite = (artistsetslugname) => {
    const [artistSlugname] = artistsetslugname[0].split('|');
    if (!artistSlugname) return;

    const newFavourites = [...favorites];
    const index = newFavourites.indexOf(artistSlugname);

    if (index >= 0) newFavourites.splice(index, 1);
    else newFavourites.push(artistSlugname);

    setUserFavourites({ artist: artistSlugname }).then(() => {
      setFavorites(newFavourites);
      updateLineupWithFavs(eventSlug, newFavourites);
    });
  };

  return (
    <div className="w-full flex flex-col items-center pt-4" id="line-up-container">
      {showFilters && venues && (
        <div className={`lineUpFilterWrapper z-radio w-full flex justify-center ${view === 'grid' ? 'bg-white-dark' : ''} `}>
          <LineupFilter
            wrappedComponentRef={(c) => {
              lineupFilterRef = c;
            }}
            {...lineUpFilter}
            dates={dates}
            tags={tags}
            venues={venues}
            filterVenueSelected={filterVenueSelected}
            filterDaySelected={filterDaySelected}
            filterTagSelected={filterTagSelected}
            updateViewMode={updateViewMode}
            updateView={updateView}
            updateActiveDays={updateActiveDays}
            activeDays={activeDays}
            activeTags={activeTags}
            activeVenues={activeVenues}
            activeViewMode={viewMode}
            activeView={view}
            searchValue={searchValue}
            updateSearchFilter={updateSearchFilter}
            showTable={showTable}
            showList={showList}
            showGrid={showGrid && showDateTime && showDate}
            showDate={showDate}
            showPoster={showPoster}
            showDateTime={showDateTime}
            multipleLineUpsOnPage={multipleLineUpsOnPage}
          />
        </div>
      )}

      {filteredArtistsByDay && view === 'list' && <LineupList eventSlug={eventSlug} eventPost={eventPost} artists={filteredArtistsByDay} isUserLoggedIn={Boolean(user?.email)} handleSetArtistSetFavorite={handleSetArtistSetFavorite} favorites={favorites} currentView={view} userFavorites={userFavorites} eventPost={eventPost} />}
      {filteredArtistsByDay && view === 'table' && <LineupTable timezone={timezone} eventSlug={eventSlug} eventPost={eventPost} artistsByDay={filteredArtistsByDay} venues={venues} showDate={showDate} showDateTime={showDateTime} showVenue={showVenue} hasConferences={hasConferences} isUserLoggedIn={Boolean(user?.email)} handleSetArtistSetFavorite={handleSetArtistSetFavorite} category={category} doNotshowMultipleVenues={doNotshowMultipleVenues} eventPost={eventPost} />}
      {showDateTime && showDate && filteredArtistsByDay && view === 'grid' && <LineupGrid timezone={timezone} venues={venues} eventSlug={eventSlug} eventPost={eventPost} category={category} artistsByDay={filteredArtistsByDay} hasConferences={hasConferences} isUserLoggedIn={Boolean(user?.email)} handleSetArtistSetFavorite={handleSetArtistSetFavorite} />}
      {showPoster && view === 'poster' && <MediaDescription imageUrl={{ en: poster }} />}
    </div>
  );
};

LineupContainer.propTypes = {
  lineUpFilter: PropTypes.shape().isRequired,
  eventSlug: PropTypes.string.isRequired,
  poster: PropTypes.string,
  multipleLineUpsOnPage: PropTypes.bool,
  showFilters: PropTypes.bool,
  showPoster: PropTypes.bool,
  showGrid: PropTypes.bool,
  showTable: PropTypes.bool,
  showList: PropTypes.bool,
  hasConferences: PropTypes.bool,
};
LineupContainer.defaultProps = {
  showFilters: false,
  showPoster: false,
  showGrid: false,
  showTable: false,
  showList: false,
  hasConferences: false,
  multipleLineUpsOnPage: false,
  poster: null,
};

export default withRouter(LineupContainer);
