/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { useAuth } from '@primaveralabs/auth';
import Link from '../../util/link';
import imageModifier from './helpers/imageModifier';
import { useRoutes } from '../../context/routesContext';
import translateObj from '../../util/translateObj';
import LoadIcon from './LoadIcon';
import isHourFormat12 from '../../util/isHourFormat12';
import { HeaderContext } from '../../context/headerContext';

const ARIA_LABEL_ADD_FAVORITE = {
  en: 'Add to favorites',
  es: 'Añadir a favoritos',
  ca: 'Afegir a favorits',
  pt: 'Adicionar aos favoritos',
};
const ARIA_LABEL_DELETE_FAVORITE = {
  en: 'Delete from favorites',
  es: 'Eliminar de favoritos',
  ca: 'Eliminar de favorits',
  pt: 'Excluir dos favoritos',
};
const ARIA_LABEL_LOGIN = {
  en: 'Open login screen',
  es: 'Abrir pantalla de inicio de sesión',
  ca: 'Obre la pantalla d\'inici de sessió',
  pt: 'Abrir tela de login',
};

const isFavoritesActive = true;

const LineupTableItem = ({
  artist,
  showTime,
  width,
  reset,
  setReset,
  eventSlug,
  match,
  showDate,
  showDateTime,
  showVenue,
  timezone,
  category,
  history,
  handleSetArtistSetFavorite,
  eventPost,
}) => {
  const { user, token } = useAuth();
  const { setAuthOpen } = useContext(HeaderContext);
  const { language, path } = useRoutes();
  const [active, setActive] = useState(false);
  const [starDisabled, setStarDisabled] = useState(false);
  const {
    artistName, artistSetReadableName, image, dateTimeStartReal, venueReadableName, artistSetIsFavorite, artistSlugName, artistSetSlugName, shortTitle,
  } = artist;

  const artistStartDate = moment(dateTimeStartReal / 1000, 'X');
  const hours12 = isHourFormat12();

  const handleFavoriteLogic = () => {
    if (!starDisabled) {
      if (token && user) {
        setStarDisabled(true);
        // logic to update user favs
        handleSetArtistSetFavorite([`${artistSlugName}|${artistSetSlugName}`]);
        setTimeout(() => {
          setStarDisabled(false);
        }, 2000);
      } else {
        // please log in
        setAuthOpen('login');
      }
    }
  };

  useState(() => {
    setActive(false);
    setReset();
  }, [reset]);

  return (
    <tr className={`border-b border-black relative ${active && width > 640 ? 'bg-barcelona' : ''}`}>
      <td>
        {starDisabled
          ? <LoadIcon height={15} width={15} classes="w-auto block justify-start pl-8 sm:pl-0" color={category} />
          : (
            <div>
              {(isFavoritesActive && (token && user))
                ? (
                  <span
                    tabIndex="0"
                    role="button"
                    className={`font-icon cursor-pointer ${artistSetIsFavorite ? 'icon-star-solid ' : 'icon-star'} text-${category} pl-8 sm:pl-0`}
                    onClick={() => handleFavoriteLogic()}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleFavoriteLogic();
                      }
                    }}
                    aria-label={artistSetIsFavorite ? translateObj(ARIA_LABEL_DELETE_FAVORITE, language) : translateObj(ARIA_LABEL_ADD_FAVORITE, language)}
                  />
                )
                : (
                  <div role="button" className="flex justify-center">
                    <button
                      type="button"
                      onClick={() => setAuthOpen('login')}
                      className={`text-${category} font-americaMonoRegular cursor-pointer text-center px-4 uppercase flex justify-center items-center`}
                      aria-label={translateObj(ARIA_LABEL_LOGIN, language)}
                    >
                      <span className="text-base text-barcelona font-icon icon-star" />
                    </button>
                  </div>
                )}
            </div>
          )}
      </td>
      <td className="flex items-center sm:items-start sm:my-5 my-2 ">
        {translateObj(image, language) && <img className="sm:block hidden ml-2 noBackgroundImgGray" src={imageModifier(translateObj(image, language), '70x60')} alt={translateObj(artistSetReadableName, language)} />}
        {translateObj(image, language) && <img className="sm:hidden block noBackgroundImgGray" src={imageModifier(translateObj(image, language), '97x50')} alt={translateObj(artistSetReadableName, language)} />}
        <div className="flex flex-col ml-5 relative w-full pr-4">
          <Link to={`/${eventPost || 'artist'}${artist.postUri}${eventSlug ? `?e=${eventSlug}` : ''}`} localizationpath={path} aria-label={artistSetReadableName ? translateObj(artistSetReadableName, language) : artistName}>
            <h3 className="font-tiemposBold text-16 sm:text-14 text-black">
              {artistSetReadableName && translateObj(artistSetReadableName, language) ? translateObj(artistSetReadableName, language) : artistName}
            </h3>
            <span className="text-12 uppercase text-black font-americaMonoBold">{shortTitle ? translateObj(shortTitle, language) : ''}</span>
          </Link>
          <p className="font-tiemposRegular text-12 my-2 sm:block hidden" tabIndex="0">
            {showDate && showDateTime ? artistStartDate.tz(timezone).format(hours12 ? 'hh:mm A' : 'HH:mm') : 'TBD'}
          </p>
          <p className="font-tiemposRegular m-0 text-12 sm:block hidden" tabIndex="0">{translateObj(venueReadableName, language)}</p>
        </div>
      </td>
      <td>
        <p className="font-tiemposRegular text-16 sm:hidden" tabIndex="0">{showDate && showDateTime ? artistStartDate.tz(timezone).format(hours12 ? 'hh:mm A' : 'HH:mm') : 'TBD'}</p>
      </td>
      {showVenue && (
      <td>
        <p className="font-tiemposRegular text-16 sm:hidden" tabIndex="0">{translateObj(venueReadableName, language)}</p>
      </td>
      )}
    </tr>
  );
};

LineupTableItem.propTypes = {
  artist: PropTypes.shape(),
  showTime: PropTypes.string,
  width: PropTypes.number,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
  eventSlug: PropTypes.string,
  match: PropTypes.shape({}).isRequired,
  showDate: PropTypes.bool,
  showDateTime: PropTypes.bool,
  showVenue: PropTypes.bool,
  timezone: PropTypes.string,
  category: PropTypes.string,
  history: PropTypes.shape().isRequired,
  handleSetArtistSetFavorite: PropTypes.func.isRequired,
  eventPost: PropTypes.string,
};

LineupTableItem.defaultProps = {
  category: 'black',
  artist: PropTypes.shape(),
  showTime: '',
  width: 0,
  reset: false,
  setReset: null,
  eventSlug: '',
  showDate: false,
  showDateTime: false,
  showVenue: false,
  timezone: '',
  eventPost: null,
};

export default withRouter(LineupTableItem);
