import gql from 'graphql-tag';

import { clientWeb, persistor } from '../graphqlAPI';

const GET_LINEUP_EVENT_QUERY = gql`
  query Get($name: String!) {
    getLineupEvent(name: $name) {
      artists {
        fontRatio
        artistSlugName
        artistName
        artistReadableName {
          es
          en
          ca
        }
        image {
          es
          en
          ca
        }
        postUri
        duration
        venues {
          duration
          venueSlugName
          artistSetSlugName
          artistSetName
          artistSetGenres
          artistSetIsFavorite
          shortTitle {
            es
            en
            ca
          }
          shortTitle {
            es
            en
            ca
          }
          smallText {
            es
            en
            ca
          }
          artistSetReadableName {
            es
            en
            ca
          }
          image {
            es
            en
            ca
          }
          dateTimeStartReal
          dateTimeStartHuman
        }
      }
      venues
      showDate
      showDateTime
      showVenue
      showTicket
      timezone
      eventPost
    }
  }
`;

const executeQuery = async (name, type = 'network') => {
  const result = await clientWeb
    .query({
      query: GET_LINEUP_EVENT_QUERY,
      variables: { name },
      fetchPolicy: type === 'network' ? 'network-only' : 'cache-only',
    })
    .then(queryResult => queryResult)
    .catch((queryError) => {
      const errorMessage = type === 'network' ? `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}` : `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error after Cache Restore: ${queryError}`;
      console.log('\x1b[31m%s\x1b[0m', errorMessage);

      return null;
    });

  const {
    data: { getLineupEvent: lineupData },
  } = result;

  if (lineupData) {
    return lineupData;
  }
  console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: lineupData = null`);
  return null;
};

const getLineup = async (name) => {
  try {
    const lineupData = await executeQuery(name);

    if (persistor) {
      persistor.persist();
    }

    return lineupData;
  } catch (jsonError) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error: ${jsonError}`);

    if (persistor) {
      const persistorStatus = await persistor.restore().then(() => 'OK');
      console.log(`${new Date().toLocaleTimeString()} ~ Client: Apollo Cache Restored - ${persistorStatus}`);

      try {
        const lineupData = await executeQuery(name, 'cache');

        return lineupData;
      } catch (jsonErrorAfterCacheRestore) {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: JSON Object Error after Cache Restore, sending 404: ${jsonErrorAfterCacheRestore}`);
        return null;
      }
    }
    return null;
  }
};

export default getLineup;
